'use client';

import classNames from 'classnames';
import { AnimatePresence, motion as m } from 'framer-motion';
import { ReactNode, useEffect, useRef } from 'react';
import CloseIcon from '../../public/images/icons/close.svg';

import st from './NewModal.module.scss';

export type ModalCloseEvent =
  | MouseEvent
  | KeyboardEvent
  | React.MouseEvent<HTMLButtonElement>;

export interface ModalProps {
  children: ReactNode;
  className?: string;
  title?: string;
  isOpen?: boolean;
  onClose?: (event?: ModalCloseEvent) => void;
  showCloseButton?: boolean;
}

export default function Modal({
  children,
  className = '',
  title,
  isOpen = false,
  onClose,
  showCloseButton = true,
}: ModalProps) {
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleMousedown(ev: MouseEvent) {
      const target = ev.target as HTMLElement;
      if (!modalContentRef?.current?.contains(target)) {
        onClose?.(ev);
      }
    }

    function handleEscapeKeyPress(ev: KeyboardEvent) {
      if (ev.key === 'Escape') {
        onClose?.(ev);
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleMousedown);
      document.addEventListener('keydown', handleEscapeKeyPress);
    }

    /** @info: remove event listeners when modal is unmounted */
    return () => {
      document.removeEventListener('mousedown', handleMousedown);
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }
    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <m.div
          id={'modal-container'}
          tabIndex={-1}
          className={classNames(st.container, className)}
          initial={{ opacity: 0, pointerEvents: 'none' }}
          animate={{ opacity: 1, pointerEvents: 'auto' }}
          exit={{ opacity: 0, pointerEvents: 'none' }}
          transition={{ duration: 0.2 }}
        >
          <div className={st.overlay} />
          <div
            role={'dialog'}
            aria-modal={'true'}
            aria-labelledby={'modal-title'}
            aria-describedby={'modal-description'}
            className={st.modal}
            ref={modalContentRef}
          >
            {(showCloseButton || title) && (
              <div className={st.header}>
                {title && (
                  <div className={st.titleSection}>
                    <h1 id={'modalTitle'} className={st.title}>
                      {title}
                    </h1>
                  </div>
                )}
                {showCloseButton && (
                  <button
                    className={st.close}
                    onClick={onClose}
                    aria-label={'Close the modal'}
                  >
                    <CloseIcon alt={'Close Icon'} height={24} width={24} />
                  </button>
                )}
              </div>
            )}
            <div className={st.body}>{children}</div>
          </div>
        </m.div>
      )}
    </AnimatePresence>
  );
}
