'use client';

import Modal from 'components/Modal/NewModal';
import { createContext, ReactNode, useContext } from 'react';
import { Modal as ModalClass, useModal } from './modal';

const ModalContext = createContext<ModalClass>({} as ModalClass);

export function ModalProvider({ children }: { children: ReactNode }) {
  const modal = useModal();
  return (
    <ModalContext.Provider value={modal}>
      {children}
      <Modal {...modal._getModalProps(false)} />
    </ModalContext.Provider>
  );
}

export { ModalContext };

export function useModalProvider() {
  return useContext(ModalContext);
}
