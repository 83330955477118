'use client';

import Script from 'next/script';
import { GoogleTagManager } from '@next/third-parties/google';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useEffect } from 'react';

function Hubspot() {
  return (
    <Script
      id="hs-script-loader"
      src="//js-eu1.hs-scripts.com/143520528.js"
      strategy="afterInteractive"
    />
  );
}

export const segment = new AnalyticsBrowser();

export default function ThirdPartyScripts() {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
      segment.load({
        writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? '',
      });
    }
  }, []);

  return (
    <>
      {process.env.NEXT_PUBLIC_NODE_ENV === 'production' && (
        <>
          <Hubspot />
          <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID ?? ''} />
        </>
      )}
    </>
  );
}
