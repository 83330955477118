import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/client/components/ThirdPartyScripts/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/client/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\",\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/app/client/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/client/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/client/providers/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/app/client/providers/ModalProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchProvider"] */ "/app/client/providers/SearchProvider.tsx");
